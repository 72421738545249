import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  [ "mp3Url", "button" ]

  connect(){
    this.button = this.buttonTarget
    this.audio = new Audio(this.mp3UrlTarget.src)
    let button = this.button
    this.audio.addEventListener("ended", function(){
      $(button).attr('class','audio-play-button pause');
    });
  }

  play(){
    if (this.audio.paused) {
      this.audio.play();
      $(this.button).attr('class','audio-play-button play');
    } else {
      this.audio.pause();
      this.audio.currentTime = 0;
      $(this.button).attr('class','audio-play-button pause');
    }
  }
}
