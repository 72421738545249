import { Controller } from "stimulus"
import Highcharts, { objectEach } from 'highcharts'

export default class extends Controller {
  static targets = ["exercise"]
  connect(){
    let workout_total = this.exerciseTarget.dataset.works
    let new_workout_total = JSON.parse(workout_total)

    let series = []
    for (let i = 0; i < new_workout_total.length; i++) {
      for(let y = 0; y < new_workout_total[i].length; y++){
        if(y == 0){
          series.push({
            name: new_workout_total[i][y],
            y: new_workout_total[i][y+1]
          })
        }
      }
    }

    Highcharts.chart('popular', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Brands',
        colorByPoint: true,
        data: series
      }]
    });
  }
}