import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['previewContainer', 'preview', 'input', 'fileName']

  connect(){
  }

  on_upload(){
    let previewContainer = this.previewContainerTarget
    let preview = this.previewTarget
    let fileName = this.fileNameTarget
    let file = event.currentTarget.files[0]
    let initSrc = this.initSrc

    if (file){
      previewContainer.classList.remove('hide')
      preview.src = URL.createObjectURL(file)
      fileName.innerHTML = file.name
    } else {
      if (initSrc){
        preview.src = initSrc
      } else {
        previewContainer.classList.add('hide')
      }
      fileName.innerHTML = ''
    }
  }

  get initSrc() {
    return (this.data.get('initSrc'))
  }
}
