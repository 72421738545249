// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    $(this.element).addClass('connected')
    this.show()
  }

  show(){
    let element = $(this.element)
    $(document).ready(function(){
      element.addClass('show')
      setTimeout(function(){
        element.removeClass('show')
      }, 4000)
      setTimeout(function(){
        element.remove()
      }, 6000)
    })
  }

  close(){
    let element = $(this.element)
    element.removeClass('show')
  }
}
