import { Controller } from "stimulus"
import Highcharts from 'highcharts'

export default class extends Controller {
  static targets = ["total"]
  connect(){
    let total_sales = this.totalTarget.dataset.sales
    
    let newData = []
    let convertTotal = JSON.parse(total_sales)
    for(let i = 0; i < convertTotal.length; i++) {
      for (let y = 0; y < convertTotal[i].length; y++) {
        if(y == 0){
          newData.push([Date.parse(convertTotal[i][y]), convertTotal[i][y+1]])
        }
      }
    }

    Highcharts.chart('container', {
      chart: {
        type: 'spline',
        zoomType: 'x',
        events: {
          click: function(e) {
            alert('hello world');
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 1000 * 60 * 60 * 24,
        dateTimeLabelFormats: { 
          day: "%e-%b-%y"
        }
      },
      yAxis: {
        title: {
        text: '$ Dollars'
        },
        min: 0
      },      
      tooltip: {
        pointFormat: '{point.x:%e. %b}: ${point.y:.2f}'
      },
  
      plotOptions: {
        series: {
          marker: {
            enabled: true
          }
        }
      },
  
      colors: ['#6CF', '#39F', '#06C', '#036', '#000'],

      series: [{
        name: "Total Sales",
        data: newData 
      }],
  
      responsive: {
        rules: [{
          condition: {
            maxWidth: 100
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      }
   });
 }
}