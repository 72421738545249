import { Controller } from "stimulus"
// import 'bootstrap/js/dist/modal'

export default class extends Controller {
  static targets = [ 'selectAll', 'checkBoxes' ]

  connect(){
  }

  select_all(){
    let selectAll = this.selectAllTarget
    let checkBoxes = this.checkBoxesTargets
    if (selectAll.checked){
      checkBoxes.forEach(checkbox => {
        checkbox.checked = true
      })
    } else {
      checkBoxes.forEach(checkbox => {
        checkbox.checked = false
      })
    }
  }

  checkbox_change(){
    let selectAll = this.selectAllTarget
    let checkBoxes = this.checkBoxesTargets
    let currentTarget = event.currentTarget
    if (currentTarget.checked) {
      let uncheckedBox = checkBoxes.find(checkbox => {
        return !checkbox.checked
      })
      if (!uncheckedBox) {
        selectAll.checked = true
      }
    } else {
      selectAll.checked = false
    }
  }

  schedule(){
    let form = this.element;
    let formData = new FormData(form);
    console.log('123');
    $.ajax({
      url: '/admin/custom_notifications/schedule',
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      error: function(jqXHR, textStatus, errorThrown){
        console.log(textStatus);
      },
      success: function(data){
        $('#schedule_modal').modal('show');
      }
    });
  }

  sendNow(){
    let form = this.element;
    let formData = new FormData(form);
    console.log('123');
    $.ajax({
      url: '/admin/custom_notifications/send_now',
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      error: function(jqXHR, textStatus, errorThrown){
        console.log(textStatus);
      },
      success: function(data){
        $('#send_now_modal').modal('show');
      }
    });
  }

  scheduleNotification(){
    let form = this.element;
    if ($('#custom_notification_scheduled_time').val() == ''){
      $('#schedule_modal .modal-inputs .error').html("Scheduled time can't be blank")
    } else {
      form.submit();
    }
  }
}
