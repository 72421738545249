// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery/src/jquery")
require("trix")
require("@rails/actiontext")
require("jquery")
require("packs/nested_form")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('images', true)
const imagePath = (name) => images(name, true)

import $ from "jquery"
require("packs/registration")

import "foundation-sites"
Foundation.addToJquery($)

import "controllers"

$(document).on('turbolinks:load', function() {
  $(document).foundation()
});

$(function(){
  $(document).foundation();
});