import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    const notificationId = this.element.dataset.notificationId
    const notificationRead = this.element.dataset.notificationRead
    if (notificationRead == 'false') {
      $(document).ready(function(){
        $.ajax({
          url: '/mobile_page/notifications/' + notificationId + '/read',
          type: 'POST',
          processData: false,
          contentType: false,
          error: function(jqXHR, textStatus, errorThrown){
            console.log(textStatus);
          },
          success: function(data){
          }
        });
      })
    }
  }
}
