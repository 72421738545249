import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect(){
    let input = this.inputTarget;
    let element = this.element;
    if (input.dataset.disableUpload == 'true') {
      input.addEventListener("trix-file-accept", function(e) {
        e.preventDefault();
      });
      element.querySelector('trix-toolbar .trix-button-group--file-tools').remove();
    }
  }
}
