import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    let element = this.element
    let maxCount = element.dataset.maxCount
    console.log($(element))
    $(document).ready(function(){
      $(`<div class='input-counter unselectable text-secondary'><span class='current-count'>0</span><span> / ${maxCount} Characters</span></div>`).insertAfter($(element))
    })
  }

  count_input(){
    let currentCount = this.element.value.length
    let maxCount = parseInt(this.element.dataset.maxCount)
    console.log(currentCount)
    console.log(maxCount)
    if (currentCount <= maxCount){
      $('.input-counter').removeClass('text-danger')
      $('.input-counter').addClass('text-secondary')
      $('.input-counter .current-count').html(currentCount)
    } else {
      $('.input-counter').removeClass('text-secondary')
      $('.input-counter').addClass('text-danger')
      $('.input-counter .current-count').html(currentCount)
    }
  }
}
