import { Controller } from "stimulus"
import Highcharts from 'highcharts'

export default class extends Controller {
  static targets = ["category"]
  connect(){
    let active_member = this.categoryTarget.dataset.active
    let new_active_member = JSON.parse(active_member)

    let trial_member = this.categoryTarget.dataset.trial
    let new_trial_member = JSON.parse(trial_member)

    let total_member = this.categoryTarget.dataset.total
    let new_total_member = JSON.parse(total_member)

    let active_newData = []
    for(let i = 0; i < new_active_member.length; i++) {
      for (let y = 0; y < new_active_member[i].length; y++) {
        if(y == 0){
          active_newData.push([Date.parse(new_active_member[i][y]), new_active_member[i][y+1]])
        }
      }
    }

    let trial_newData = []
    for(let i = 0; i < new_trial_member.length; i++) {
      for (let y = 0; y < new_trial_member[i].length; y++) {
        if(y == 0){
          trial_newData.push([Date.parse(new_trial_member[i][y]), new_trial_member[i][y+1]])
        }
      }
    }

    let total_newData = []
    for(let i = 0; i < new_total_member.length; i++) {
      for (let y = 0; y < new_total_member[i].length; y++) {
        if(y == 0){
          total_newData.push([Date.parse(new_total_member[i][y]), new_total_member[i][y+1]])
        }
      }
    }

    Highcharts.chart('member', {
      chart: {
        type: 'spline',
        zoomType: 'x'
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 1000 * 60 * 60 * 24,
        dateTimeLabelFormats: { 
          day: "%e-%b-%y"
        }
      },
      yAxis: {
        title: {
          text: 'Active Members'
        },
        min: 0
      },
      tooltip: {
        pointFormat: '{point.x:%e. %b}: {point.y:2f} Members'
      },
  
      plotOptions: {
        series: {
          marker: {
            enabled: true
          },
          point: {
            events: {
              click: function() {
                window.location.replace("/admin/dau?date="+this.x);
              }
            }
          }
        }
      },
  
      colors: ['#F30732', '#1907F3', '#08D624', '#EC08F7', '#000'],

      series: [{
        name: "New Active Members",
        data: active_newData
      }, {
        name: "Return Active Members",
        data: trial_newData
      }, {
        name: "Total",
        data: total_newData
      }],
  
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }]
      }
   });
 }
}