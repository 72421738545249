import { Controller } from "stimulus"
import Highcharts, { objectEach } from 'highcharts'

export default class extends Controller {
  static targets = ["movement"]
  connect(){
    let workout_total = this.movementTarget.dataset.total
    let new_workout_total = JSON.parse(workout_total)

    let series = []
    for (let i = 0; i < new_workout_total.length; i++) {
      for(let y = 0; y < new_workout_total[i].length; y++){
        if(y == 0){
          series.push({
            name: new_workout_total[i][y],
            data: new_workout_total[i][y+1].map(function(point) {
              return [Date.parse(point[0]), point[1]]
            })
          })
        }
      }
    }

    Highcharts.chart('workout', {
      chart: {
        type: 'column',
        height: 500
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { 
          day: "%e-%b-%y"
        }
      },
      yAxis: {
        title: {
          text: 'REPS Completed',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        },
        min: 0
      },
      tooltip: {
        pointFormat: '{series.name} {point.x:%e. %b}: {point.y:2f} REPS'
      },
  
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
      },

      credits: {
        enabled: false
      },

      series: series

    });
  }
}