import { Controller } from "stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets =  [ 'dropdownPane', 'dobrange' ]

  connect(){
    $(this.dropdownPaneTarget).click(function(e) {
      e.stopPropagation();
    });
    flatpickr(this.dobrangeTarget ,{
      mode: "range",
      altInput: true,
      altFormat: "d-m-Y"
    })
    $('.flatpickr-calendar').click(function(e) {
      e.stopPropagation();
    });
  }
}
