import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "cardElement", "cardErrors", "submitButton", "freecontrol" ]
  style = {
    base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        }
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
    }
  }
  stripe = Stripe(this.data.get('key'));
  card = this.stripe.elements().create('card', {
    hidePostalCode: true,
    style: this.style
  })

  connect() {

    this.card.mount(this.cardElementTarget)
    
    let controller = this;
    this.card.addEventListener('change', function(e){
      var displayError = controller.cardErrorsTarget
      displayError.textContent = e.error ? e.error.message : ''
    })
  }

  submitForm(e) {
    if (!this.freecontrolTarget.checked) {
      e.preventDefault()

      let controller = this;
      this.stripe.createToken(this.card).then(function(result){
        if (result.error) {
          var errorElement = controller.cardErrorsTarget
          errorElement.textContent = result.error.message
        } else {
          controller.stripeTokenHandler(result.token)
        }
      })
    }
  }

  stripeTokenHandler(token){
    var form = this.formTarget
    var submitButton = this.submitButtonTarget
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'user[stripe_token]')
    hiddenInput.setAttribute('value', token.id)
    form.appendChild(hiddenInput)

    submitButton.value = 'Please wait...'
    submitButton.disabled = true
    form.submit()
  }
}
