// var Registration = {
//   init: function(){
//   },  
// }
$(document).ready(function(){
  var inputObj1 = $('#user_sub_plan_monthly_subscription')
  var inputObj2 = $('#user_sub_plan_yearly_subscribe')
  inputObj1.parent().addClass("clicked");
  $('.pay-selection label').click(function(){
    // console.log(inputObj1)
    if(inputObj1.prop("checked")==true){
      inputObj1.parent().addClass("clicked");
      inputObj2.parent().removeClass("clicked");
    }else{
      inputObj2.parent().addClass("clicked");
      inputObj1.parent().removeClass("clicked");
    }
  });

  $('.clickable-box').click(function(){
    var selectObj = $('.clickable-box input');
    if(selectObj.prop("checked")==true){
      selectObj.prop("checked",false)
      $('.clickable-box').removeClass("clicked");
    }
    else{
      selectObj.prop("checked",true);
      $('.clickable-box').addClass("clicked");
    }
  })
})
