import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template"]

  connect(){
    this.reset_position()
  }

  dragstart(e) {
    e.dataTransfer.setData("drag-key", e.target.getAttribute("data-position"))
    e.dataTransfer.effectAllowed = "move"
  }

  dragover(e) {
    e.preventDefault()
    return true
  }

  dragenter(e) {
    e.preventDefault()
  }

  drop(e) {
    e.preventDefault()
    var data = e.dataTransfer.getData("drag-key")
    const dropTarget = e.target.closest(".nested-field-panel")
    const draggedItem = this.element.querySelector(`[data-position='${data}']`);
    if (dropTarget != null && draggedItem != null){
      const positionComparison = dropTarget.compareDocumentPosition(draggedItem)
      if ( positionComparison & 4) {
        dropTarget.insertAdjacentElement('beforebegin', draggedItem);
      } else if ( positionComparison & 2) {
        dropTarget.insertAdjacentElement('afterend', draggedItem);
      }
    }
  }

  dragend(e) {
    e.preventDefault()
    this.reset_position()
  }

  add_association(e) {
    e.preventDefault()
    let currentPosition = document.querySelectorAll('.nested-field-panel:not(.hide)').length + 1
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf()).replace(/TEMPLATE_POSITION/g, currentPosition)
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(e) {
    e.preventDefault()
    let item = e.target.closest(".nested-field-panel")
    item.querySelector("input[name*='_destroy']").value = 1
    item.dataset.position = ''
    item.classList.add('hide')
    this.reset_position()
  }

  reset_position(){
    document.querySelectorAll('.nested-field-panel:not(.hide)').forEach((e, index) => {
      e.dataset.position = index+1
      e.querySelector('.panel-title .title').textContent = 'Exercise ' + (index+1)
      e.querySelector("input[name*='position']").value = index+1
    })
  }
}